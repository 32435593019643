import React, { useState } from "react";
import icon1 from "../assets/images/service-item1.svg";
import icon2 from "../assets/images/service-item2.svg";
import icon3 from "../assets/images/service-item3.svg";
import icon4 from "../assets/images/service-item4.svg";

const services = [
  { id: 1, icon: icon2, label: 'iOS Developement' },
  { id: 2, icon: icon3, label: 'Android Developement' },
  { id: 3, icon: icon1, label: 'IoT Application Development' }, 
  { id: 4, icon: icon4, label: 'Software Developement' }
];

const data = [
  {
    id: 1, 
    content: `Are you looking for an iPhone application development business that can assist you in reaching a wider audience? The one you're looking for might be among the experts at Ganvian. Get in touch and make the most of iOS mobile app development to provide your iOS audience with truly useful and compelling items and stand out in the market.`
  },
  {
    id: 2,
    content: `For the complete range of Android devices, our highly qualified Android Mobile Application Development service providers have years of expertise in creating mobile apps that are unique, stable, functional, and futuristic. We provide the best and most skilled team of developers to all of our clients. Their skill sets line up with the requirements of your project. They start working efficiently and devotedly for you as soon as you hire them keeping all the aspects of your requirement into consideration.`
  },
  {
    id: 3,
    content: `Our IoT solution makes use of intelligent devices that are synced with cloud computing services and analytics to assist businesses in fostering an actionable IoT design, interpreting data, and enforcing strategies consisting of diversified plans ranging from idea validation and roadmap creation to selecting the best IoT platform. So that you get the best IoT development company, automate the processes, and boost your ROI with high-end IoT application development to improve your business competency. We consider it our duty as a respected IoT app development firm to increase your industrial exposure with our cutting-edge IoT solution architecture that promotes corporate growth.`
  },
  {
    id: 4,
    content: `Modern software development has a hugely favorable impact on how the business world develops and how it continues to expand. Ganvian provides every organization with outstanding custom software development services that enable them to achieve scalable solutions and genuine business value. Our specialized web development services streamline corporate processes from concept to coding, development to deployment, and increase revenues.`
  }
]

const ServiceGroup = () => {
  const [activeIndex, setActiveIndex] = useState();
  const [isShowDetail, setIsShowDetail] = useState(false);

  const handleReadDetail = (id) => {
    const readMoreElement = document.getElementById("service-detail");
    if (id === activeIndex) {
      if(isShowDetail) {
        readMoreElement.classList.remove('expanded');
        setIsShowDetail(false);
      } else {
        readMoreElement.classList.add('expanded');  
        setIsShowDetail(true);
      }
    } else {
      readMoreElement.classList.add('expanded');
      setIsShowDetail(true);
    }
    setActiveIndex(id);
  };

  return (
    <div className="section-container">
      <div className="service-groups">
        {services.map((service) => (
          <div className={`service-item ${activeIndex === service.id ? 'selected' : ''}`} key={service.id} onClick={() => handleReadDetail(service.id)}>
            <img src={service.icon} alt="" />
            <p>{service.label}</p>
          </div>
        ))}
      </div>
      <div className="service-detail" id="service-detail">
        <div className="service-detail-container">
          <h3 className="service-detail-title">{services.find((item) => item.id === activeIndex)?.label}</h3>
          <h3>
            {data.find((item) => item.id === activeIndex)?.content}
          </h3>
        </div>
      </div>
    </div>    
  );
};

export default ServiceGroup;
