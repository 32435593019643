import React, { useState } from "react";

const Aboutus = () => {
  const [isReadMore, setIsReadMore] = useState(false);

  const handleReadMore = () => {
    setIsReadMore(!isReadMore);
    const readMoreElement = document.getElementById("aboutus-read-more");
    if (isReadMore) {
      readMoreElement.classList.remove('expanded');
    } else {
      readMoreElement.classList.add('expanded');
    }
  };
  return (
    <section id="aboutus" className="aboutus">      
      <div className="section-container">
        <h6 className="section-title">About <span>Us</span></h6>
        <div className="first-section">
          <div className="section-container flex-block">
            <div className="text-section aboutText">
              <h3>
              We offer advanced strategies for software development, application development, product management, iOS development, and more. Our mission is to provide cutting-edge software solutions and applications that deliver optimum user experiences.
              </h3>
              <h3>
              We specialize in MVPs, ensuring that our solutions are tailored to our clients needs, concepts, and models. We pride ourselves on our quality, reliability, innovation, and commitment to customer satisfaction. Our services include iOS and Android app development, IoT application development, software development, mobile application design, React Native development, and product management.
              </h3>
              <div className="read-more" id="aboutus-read-more">
                <h3>
                At Ganvian, we value authenticity, transparency, and continuous self-improvement. We strive to integrate advanced technology into everyone's lives and foster long-term business relationships. Our team collaborates closely with our clients to ensure that their products are not only successful but also user-friendly and meet their needs. Get in touch with us today to start a successful partnership.
                </h3>
              </div>
              <div className="action-groups">
                <button className="btn outline-btn" onClick={() => handleReadMore()}>Read {isReadMore ? 'Less' : 'More'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;