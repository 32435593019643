import React, { useState, useEffect } from "react";
import Header from "./Header";
import mainImg from "../assets/images/hero.svg";

const Hero = () => {
  const [sticky, setSticky] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    scrollTop >= 156 ? setSticky(true) : setSticky(false);
  };

  const handleReadMore = () => {
    setIsReadMore(!isReadMore);
    const readMoreElement = document.getElementById("read-more");
    if (isReadMore) {
      readMoreElement.classList.remove('expanded');
    } else {
      readMoreElement.classList.add('expanded');
    }
  };

  return (
    <section className={`hero-section ${sticky ? 'sticky' : ''}`} id="home">
      <Header sticky={sticky} />
      <div className="section-container flex-block">
        <div className="text-section">
          <h1>Innovation in Software Creation is <span>What We Focus On!</span></h1>
          <h3>We always seek the best way to help our customers with good agility providing them the top quality IT Strategic Consulting Serves & Support.</h3>
          <h3>
            Assisting our customers with various Digital Services such as Software Development, Mobile Application Development, Product Management, and more!
          </h3>
          <div className="read-more" id="read-more">
            <h3>
              We are a native one-stop solution with an advanced strategy for software solutions for any business. We improve the digital presence in the most efficient way possible through software development, application development, product management, iOS development, and more.
            </h3>
          </div>
          <div className="action-groups">
            {/* <button className="btn primary-btn">Free Consultation</button> */}
            <button className="btn outline-btn" onClick={() => handleReadMore()}>Read {isReadMore ? 'Less' : 'More'}</button>
          </div>
        </div>
        <div className="image-section">
          <img src={mainImg} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Hero;