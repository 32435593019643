import React from "react";
import Hero from "../components/Hero";
import OurCompany from "../components/OurCompany";
import OurServices from "../components/OurServices";
import Testimonials from "../components/Testimonicals";
import Contact from "../components/Contact";
import Technologies from "../components/Technologies";
import Footer from "../components/Footer";
import Aboutus from "../components/AboutUs";

const HomePage = () => {
  return (
    <div className="home-page">
      <Hero />
      <OurCompany />
      <OurServices />
      <Testimonials />
      <Aboutus />
      <Contact />
      <Technologies />
      <Footer />
    </div>
  );
};

export default HomePage;
