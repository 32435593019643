import React, { useState } from "react";
import mainImg from "../assets/images/company.svg";

const OurCompany = () => {
  const [isReadMore, setIsReadMore] = useState(false);

  const handleReadMore = () => {
    setIsReadMore(!isReadMore);
    const readMoreElement = document.getElementById("company-read-more");
    if (isReadMore) {
      readMoreElement.classList.remove('expanded');
    } else {
      readMoreElement.classList.add('expanded');
    }
  };

  return (
    <section className="our-company" id="about">
      <h6 className="section-title">Our <span>Company</span></h6>
      <div className="section-container flex-block reverse">
        <div className="text-section">
          <h1>Great minds specializing in <span>Software</span> and <span>Application Development!</span></h1>
          <h3>
            It’s an undeniable fact that Innovation helps your business stay ahead of the competition.
            It helps you predict the market and keep up with customer needs. Whether it’s an application or a website, without innovation, it’s impossible 
            for any business to stand out from the crowd. In this competitive market, we assist in filling up the demand-supply gap and enable firms to 
            make the most of cutting-edge and innovative technologies with quality, trust, and excellence. 
          </h3>
          <div className="read-more" id="company-read-more">
            <h3>
              We treat every one of our tasks as if it were personal homework and provide each of our customers the consideration they deserve. We add value to their business regardless of whether it is a large brand or a start-up company.
              With usual custom needs, the challenge of making them user-friendly, and dealing with varied audiences, we have built numerous complex projects successfully. Why not collaborate with us to walk down a successful path? Get in touch with us today!
            </h3>            
          </div>
          <div className="action-groups">            
            <button className="btn outline-btn" onClick={() => handleReadMore()}>Read {isReadMore ? 'Less' : 'More'}</button>
          </div>
        </div>
        <div className="image-section">
          <img src={mainImg} alt="" />
        </div>
      </div>
    </section>
  );
};

export default OurCompany;