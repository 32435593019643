import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import LogoImg from "../assets/images/logo.svg";
import MenuImg from "../assets/images/menu.svg";
import CloseImg from "../assets/images/close.png";

const Header = ({ sticky }) => {
  const [activeLink, setActiveLink] = useState('home');
  const [visibleMobileMenu, setVisibleMobileMenu] = useState(false);
  
  useEffect(() => {
    window.addEventListener('scroll', handleActiveLink);
    return () => {
      window.removeEventListener('scroll', handleActiveLink);
    };
  });

  const handleActiveLink = (e) => {
    const links = ['home', 'aboutus', 'services'];
    setActiveLink('');
    if (window.scrollY === 0) {
      setActiveLink('home');
    }

    if (window.scrollY > 4100) {
      setActiveLink('aboutus');
    }

    for(const link of links) {
      const element = document.getElementById(link);
      if (element) {
        const clientTop = element.offsetTop;
        const clientHeight = element.clientHeight;
        if ((window.scrollY + 75 < clientTop + clientHeight) && (window.scrollY + 75 > clientTop)) {
          setActiveLink(link);
          return;
        }
      }
    }
  };

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -70; 
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

  const handleMobileMenuClick = (link) => {
    setVisibleMobileMenu(false);
    setActiveLink(link);
  };

  return (
    <header className={`header-section ${sticky ? 'sticky' : ''}`}>
      <div className="header-container">
        <img src={LogoImg} alt="" />
        <div className="header-main-content mobile-hidden">
          <HashLink smooth to="#top" className={activeLink === 'home' ? 'active' : ''} onClick={() => setActiveLink('home')}>Home</HashLink>
          <HashLink smooth to="/#services" className={activeLink === 'services' ? 'active' : ''} onClick={() => setActiveLink('services')}>Services</HashLink> 
          {/* <HashLink scroll={el => scrollWithOffset(el)} to="/#testimonials" className={activeLink === 'testimonials' ? 'active' : ''} onClick={() => setActiveLink('testimonials')}>Testimonials</HashLink> */}
          <HashLink scroll={el => scrollWithOffset(el)} to="/#aboutus" className={activeLink === 'aboutus' ? 'active' : ''}  onClick={() => setActiveLink('aboutus')}>About Us</HashLink>
        </div>
        <HashLink smooth to="/#contact" className="btn primary-btn small mobile-hidden">Contact Us</HashLink>
        <img src={MenuImg} className="menu-icon desktop-hidden" alt="" onClick={() => setVisibleMobileMenu(true)} />
      </div>
      <div className={`desktop-hidden mobile-menu ${visibleMobileMenu ? 'move-to-right' : 'move-to-left'}`}>
        <img src={CloseImg} alt="" className="close-icon" onClick={() => setVisibleMobileMenu(false)} />
        <div className="mobile-menu-content">
          <ul>
            <li>
              <HashLink smooth to="#top" className={activeLink === 'home' ? 'active' : ''} onClick={() => handleMobileMenuClick('home')}>Home</HashLink>
            </li>
            <li>
              <HashLink smooth to="/#services" className={activeLink === 'services' ? 'active' : ''} onClick={() => handleMobileMenuClick('services')}>Services</HashLink>
            </li>
            {/* <li>
              <HashLink smooth to="/#testimonials" className={activeLink === 'testimonials' ? 'active' : ''} onClick={() => handleMobileMenuClick('testimonials')}>Testimonials</HashLink>
            </li> */}
            <li>
              <HashLink smooth to="/#aboutus" className={activeLink === 'aboutus' ? 'active' : ''}  onClick={() => handleMobileMenuClick('aboutus')}>About Us</HashLink>
            </li>
            <li>
              <HashLink smooth to="/#contact" className="btn primary-btn small" onClick={() => handleMobileMenuClick('')}>Contact Us</HashLink>
            </li>
          </ul>
        </div>
      </div>         
    </header>
  );
};

export default Header;