import React from "react";
import facebookIcon from "../assets/images/facebook.svg";
import twitterIcon from "../assets/images/twitter.svg";
import instagramIcon from "../assets/images/instagram.svg";
import linkedIcon from "../assets/images/linked.svg";

const Footer = () => {
  return (
    <footer>      
      <div className="footer-bottom-section">
        <div className="footer-bottom-section-container">
          <p>© 2021 Gavian Company</p>
          <div className="social-links">
            {/* <li>
              <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                <img src={facebookIcon} alt="" />
              </a>
            </li> */}
            <li>
              <a rel="noreferrer">
                <img src={linkedIcon} alt="" />
              </a>
            </li>
            {/* <li>
              <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                <img src={instagramIcon} alt="" />
              </a>
            </li> */}
            <li>
              <a rel="noreferrer">
                <img src={twitterIcon} alt="" />
              </a>
            </li>            
          </div>
        </div>        
      </div>
    </footer>
  );
};

export default Footer;
