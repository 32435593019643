import React from "react";
import { Link } from "react-router-dom";

const aboutLinks = [
  { id: 1, link: '/', label: 'Our Company' },
  { id: 2, link: '/', label: 'How we Work' },
  { id: 3, link: '/', label: 'MVP' },
  { id: 4, link: '/', label: 'Startup' },
  { id: 5, link: '/', label: 'Careers' },
  { id: 6, link: '/', label: 'Contact Us' },
  { id: 7, link: '/', label: 'Blog' }
];

const serviceLinks = [
  { id: 1, link: '/', label: 'iOS App Development' },
  { id: 2, link: '/', label: 'Android app development' },
  { id: 3, link: '/', label: 'IoT Application Development' },
  { id: 4, link: '/', label: 'Software Development' },
  { id: 5, link: '/', label: 'Mobile App Design' },
  { id: 6, link: '/', label: 'React Native Development' }
];

const productLinks = [
  { id: 1, link: '/', label: 'Mobile Game Apps' },
  { id: 2, link: '/', label: 'HealthCare Apps' },
  { id: 3, link: '/', label: 'Government Apps' },
  { id: 4, link: '/', label: 'Enterprises Apps' },
  { id: 5, link: '/', label: 'Events & Entertainment Apps' }
];

const Technologies = () => {
  return (
    <section id="technologies" className="technologies-section">
      <div className="section-container">
        <div className="footer-top-section">
          {/* <div className="link-group-item">
            <h5>About Us</h5>
            <ul>
              {aboutLinks.map((linkItem) => (
                <li key={linkItem.id}>
                  <Link to={linkItem.link}>{linkItem.label}</Link>
                </li>
              ))}                            
            </ul>
          </div> */}
          <div className="link-group-item">
            <h5>Services</h5>
            <ul>
              {serviceLinks.map((linkItem) => (
                <li key={linkItem.id}>
                  <Link to={linkItem.link}>{linkItem.label}</Link>
                </li>
              ))}                            
            </ul>
          </div>
          <div className="link-group-item">
            <h5>Products</h5>
            <ul>
              {productLinks.map((linkItem) => (
                <li key={linkItem.id}>
                  <Link to={linkItem.link}>{linkItem.label}</Link>
                </li>
              ))}                            
            </ul>
          </div>
          <div className="link-group-item">
            <h5>Contact Us</h5>
            <ul>
              <li>                  
                <a href="tel:+44 74481 67373">+44 74481 67373</a>
              </li>
              <li>
              <a href="tel:+91 97316 35695">+91 97316 35695</a>
              </li>
              <li>                
                <a href="mailto:admin@ganvian.com">admin@ganvian.com</a>
              </li>
              {/* <li>                
                <Link to="/">EUA</Link>
              </li>                   */}
            </ul>
          </div>
        </div>        
      </div>
    </section>
  );
};

export default Technologies;
