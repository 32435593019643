import React, { useState } from "react";
import mainImg1 from "../assets/images/service1.svg";
import mainImg2 from "../assets/images/service2.svg";
import ServiceGroup from "./ServiceGroup";

const OurServices = () => {
  const [isReadMore, setIsReadMore] = useState(false);

  const handleReadMore = () => {
    setIsReadMore(!isReadMore);
    const readMoreElement = document.getElementById("service-read-more");
    if (isReadMore) {
      readMoreElement.classList.remove('expanded');
    } else {
      readMoreElement.classList.add('expanded');
    }
  };
  return (
    <>
      <section className="our-services" id="services">
        <h6 className="section-title">Our <span>Services</span></h6>
        <ServiceGroup />
        <div className="first-section">
          <div className="section-container flex-block">
            <div className="text-section">
              <h1>Taking your <span>business</span> to the next level!</h1>
              <h3>
                We focus on delivering top-notch quality and best user experience application and software development solutions that expand your reach, improve your customer engagement, and take your business to the next level. Our experts are highly focused on the consumer's requirements and the current market trend. And having these things in mind they aim to provide such an application that is intuitive, usable, and adaptable.
              </h3>
              <div className="read-more" id="service-read-more">
                <h3>
                  Our sole purpose is to help your business grow exponentially providing the most effective and efficient solutions in all digital services from prototyping and design, mobile automation, cloud computing, and outsourcing to everything being technology partners. Having years of relevant experience, our bring such innovation in software creation experts that helps market forecasting while also meeting clients' expectations at the same time.
                </h3>
              </div>
              <div className="action-groups">
                <button className="btn outline-btn" onClick={() => handleReadMore()}>Read {isReadMore ? 'Less' : 'More'}</button>
              </div>
            </div>
            <div className="image-section">
              <img src={mainImg1} alt="" />
            </div>
          </div>
        </div>
        <div className="second-section">
          <div className="section-container flex-block reverse">
            <div className="text-section">
              <h1>We Are Transforming the <span>Digital Landscape</span></h1>
              <h3>
                Ganvian, a pioneer in technological exploration, is dedicated to selling high-caliber software and applications all over the world. Ganvian's overarching goal is to create and expand cutting-edge information technology for the optimum user experience. The best quality available in the market, complete client satisfaction, on-time solution delivery, and the highest quality product are all goals. Our objective is on providing a high level of user-friendliness for our products through positive, imaginative and dedicated creative specialists.
              </h3>          
            </div>
            <div className="image-section">
              <img src={mainImg2} alt="" />
            </div>
          </div>
        </div>      
      </section>
    </>    
  );
};

export default OurServices;